requirejs.config({
	baseUrl: '/wp-content/themes/carters/assets/js',
	shim : {
		bootstrap : { deps :['jquery'] }
	},
	paths: {
		jquery: 'https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min',
		jqueryUi: 'https://code.jquery.com/ui/1.12.1/jquery-ui',
		bootstrap: 'https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min',
		googleMaps: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyAYnbMM6tcAgtDoV7vb2TRzzCzoAVS6xm4',
		matchHeight: 'https://cdnjs.cloudflare.com/ajax/libs/jquery.matchHeight/0.7.2/jquery.matchHeight-min',
		aos: 'https://cdn.rawgit.com/michalsnik/aos/2.1.1/dist/aos',
		//p4
		map: './lib/p4/map',
		accordion: './lib/p4/accordion',
		//page modules
		//app
		postsList: './app/loadPosts',
		menu: './app/menu',
		search: './app/search',
		carousel: './app/carousel',
		projectCarousel: './app/project-carousel',
		projectMap: './app/project-map',
		sectors: './app/sectors',
		//home: './app/home',
	}
});

var dependencies = ['jquery', 'matchHeight', 'aos', 'bootstrap', 'postsList', 'accordion', 'menu', 'search', 'carousel', 'sectors', 'projectCarousel', 'projectMap', 'map'];

if(initState && initState.page !== undefined) {
	dependencies.push(initState.page.module);
}

requirejs(dependencies, function($, matchHeight, aos, bootstrap, postsList, accordion, menu, search, carousel, sectors, projectCarousel, projectMap, map, page) {
	$('.match-group .match').matchHeight({ byRow: true });
	$('.match-group-all .match').matchHeight({ byRow: false });
	$('.post-wrapper[data-post-type="location"] .post').matchHeight();

	aos.init();

	var $root = $('html, body');
	$('a.scroll').click(function() {
		$root.animate({
			scrollTop: $( $.attr(this, 'href') ).offset().top
		}, 500);
		return false;
	});

	$(window).scroll(function(){
		var height = $("section.page-header").height();
		$(".scroll-down").css("opacity", 1 - $(window).scrollTop() / (height / 2));
	});

	$('.count').each(function () {
		$(this).prop('Counter',0).animate({
			Counter: $(this).text()
		}, {
			duration: 4000,
			easing: 'swing',
			step: function (now) {
				$(this).text(Math.ceil(now));
			}
		});
	});
});
